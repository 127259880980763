import { DomainEvent } from "@cube/tracking-library-api";
import { DomainEventMetaInfo } from "@cube/tracking-library-api/dist/@types/events/DomainEventMetaInfo";
import { PromotionBlock, PromotionData } from "@cube/tracking-library-api/dist/@types/events/fragments";

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface ENTDWindow extends Window {
    dataLayer?: Record<string, string | string[]>[];
    CubeDataLayer: DomainEvent[] | undefined;
    CampAktionsflagsData: number[] | undefined;
}

export const promotion_banner = "promotion_banner";
export const site_variation = "site_variation";

export const view = "view";
export const click = "click";
export const test = "test";

export const header = "header";
export const headerDetails = "header-details";
export const middle = "middle";
export const middleDetails = "middle-details";
export const footer = "footer";
export const layer = "layer";
export const categorybanner = "categorybanner";
export const productbanner = "productbanner";
export const doublecontentbanner = "doublecontentbanner";
export const inlisttile = "inlisttile";
export const headerBenefit = "headerBenefit";

export const ENTD = "ENTD";

export type EventCategory = typeof promotion_banner | typeof site_variation;
export type EventType = typeof view | typeof click | typeof test;
export type EventPosition = typeof header | typeof middle | typeof footer | typeof layer | typeof headerDetails | typeof middleDetails;
export type Placement =
    | typeof header
    | typeof categorybanner
    | typeof footer
    | typeof layer
    | typeof productbanner
    | typeof doublecontentbanner
    | typeof inlisttile
    | typeof headerBenefit;

export type KampagnenEvent = DomainEventMetaInfo &
    PromotionBlock<PromotionData> & {
        event_data: {
            is_interaction_event: boolean;
            event_category: EventCategory;
            event_type: EventType;
            event_position?: EventPosition;
            event_context?: string;
            event_content?: string[];
        };
    };

export type ABTestEvent = DomainEventMetaInfo & {
    event_data: {
        is_interaction_event: boolean;
        event_category: EventCategory;
        event_type: EventType;
        event_position?: EventPosition;
        event_context?: string;
        event_content?: string[];
    };
};
